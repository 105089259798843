<template>
  <div>
    <div style="font-size: 14px">基础信息</div>
    <Row class="p-b-5 p-t-10" style="line-height: 24px">
      <i-col span="8"
        ><span class="title">编号 </span>{{ spaceInfo.code }}</i-col
      >
      <i-col span="8"
        ><span class="title">名称 </span>{{ spaceInfo.name }}</i-col
      >
      <i-col span="8"
        ><span class="title">类型 </span>{{ spaceInfo.resourceTypeName }}</i-col
      >
      <i-col span="8"
        ><span class="title">创建时间 </span>{{ spaceInfo.createTime }}</i-col
      >
      <i-col span="8"
        ><span class="title">创建人 </span>{{ spaceInfo.createUserName }}</i-col
      >
      <i-col span="24"
        ><span class="title">备注 </span>{{ spaceInfo.remark || "-" }}</i-col
      >
    </Row>
    <Tabs value="device" class="text-white">
      <TabPane label="资产明细" name="device">
        <Row>
          <i-col span="24" class="m-b-5">
            <a
              style="float: right; line-height: 30px"
              @click="handleChoiceDevices()"
              >+添加资产</a
            >
          </i-col>
        </Row>
        <Table stripe :columns="columnDevicedata" :data="deviceArray"></Table>
      </TabPane>
    </Tabs>

    <choiceDeviceModal  ref="deviceChoice" :deviceArray="deviceArray"></choiceDeviceModal>
    <!-- <Row>
      <i-col span="24" class="m-b-5">
        <a
          style="float: right; line-height: 30px"
          @click="handleChoiceDevices()"
          >+添加资产</a
        >
      </i-col>
    </Row> -->
    <!-- <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table> -->
  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'
// import { ParseDate } from '@/utils/dateFormat'
import choiceDeviceModal from './choiceDevice.vue'
import { toMoney } from '@/utils/wnumb_own'
import { downloadFileRequest } from '@/utils/download'
/** new api */
import {
  listSinInBusiness,
  orderRefund
} from '@/api/contract/invest'

import { getSpace, spaceDeviceList, deleteSpaceDevice } from '@/api/product/leaseSpace'
export default {
  components: {
    choiceDeviceModal
  },
  props: {},
  data () {
    return {
      spaceInfo: {},

      tableData: [],
      columnData: [
        {
          title: '用户名',
          key: 'userName'
        },
        { title: '联系电话', key: 'userPhone' },
        { title: '报名时间', key: 'createTime' },
        {
          title: '上传材料',
          key: 'freePeriod',
          render: (h, params) => {
            const result = []
            params.row.uploadMaterialList.forEach((item) => {
              result.push(
                h('p', [
                  h(
                    'a',
                    {
                      style: {
                        marginRight: '5px'
                      },
                      on: {
                        click: () => {
                          downloadFileRequest(item.value, {}, item.name)
                        }
                      }
                    },
                    item.name
                  )
                ])
              )
            })
            return h('div', result)
          }
        },
        { title: '保证金状态', key: 'orderStatusName' },
        { title: '状态', key: 'publicStatusName' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const refundButton = h(
              'a',
              {
                style: {
                  color: 'red',
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    let remark = ''
                    this.$Modal.confirm({
                      title: '客户退款',
                      render: (h) => {
                        return h('Input', {
                          props: {
                            value: remark,
                            placeholder: '请输入退款备注'
                          },
                          on: {
                            'on-change': (event) => {
                              remark = event.target.value
                            }
                          }
                        })
                      },
                      onOk: () => {
                        orderRefund({
                          investId: this.investmentId,
                          orderNo: params.row.orderNo,
                          remark: remark
                        }).then((res) => {
                          if (res && !res.errcode) {
                            this.$Notice.success({ desc: '退款成功' })
                            // window.location.reload()
                            this.initSinInBusiness()
                          }
                        })
                      }
                    })
                  }
                }
              },
              '退款'
            )

            return h('div', params.row.orderStatus === 1 ? [refundButton] : [])
          }
        }
      ],

      deviceArray: [],
      columnDevicedata: [
        {
          title: '编号',
          key: 'innerCode',
          render: (h, params) => {
            return h('span', params.row.device.innerCode)
          }
        },
        {
          title: '类型',
          align: 'center',
          key: 'devicemodelName',
          render: (h, params) => {
            return h('span', params.row.device.devicemodelName)
          }
        },
        {
          title: '站点',
          align: 'center',
          key: 'stationName',
          render: (h, params) => {
            return h('span', params.row.device.stationName)
          }
        },
        {
          title: '楼层',
          align: 'center',
          key: 'floor',
          render: (h, params) => {
            return h('span', params.row.device.floor)
          }
        },
        {
          title: '位置',
          align: 'center',
          key: 'positionName',
          render: (h, params) => {
            return h('span', params.row.device.positionName)
          }
        },
        {
          title: '面积（㎡）',
          align: 'center',
          key: 'area',
          render: (h, params) => {
            return h('span', params.row.device.area)
          }
        },
        {
          title: '经营方',
          align: 'center',
          ellipsis: true,
          tooltip: true,
          key: 'manageUnitName',
          render: (h, params) => {
            return h('span', params.row.device.manageUnitName)
          }
        },

        {
          title: '通电',
          align: 'center',
          key: 'electric',
          render: (h, params) => {
            return h('span', params.row.device.electric ? '是' : '否')
          }
        },
        {
          title: '通水',
          align: 'center',
          key: 'waterSupply',
          render: (h, params) => {
            return h('span', params.row.device.waterSupply ? '是' : '否')
          }
        },
        {
          title: '通气',
          align: 'center',
          key: 'gasSupply',
          render: (h, params) => {
            return h('span', params.row.device.gasSupply ? '是' : '否')
          }
        },
        {
          title: '消防验收',
          align: 'center',
          key: 'fireApproval',
          render: (h, params) => {
            return h('span', params.row.device.fireApproval ? '是' : '否')
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 120,
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px',
                  color: 'red'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作提示',
                      content: '确定要移除此项资产？',
                      onOk: () => {
                        deleteSpaceDevice({ id: params.row.id }).then(res => {
                          this.initDevices()
                        })
                      }
                    })
                  }
                }
              },
              '移除'
            )
            return h('div', [detailButton])
          }
        }
      ]
    }
  },
  mounted () {},
  created () {
    if (this.spaceId) {
      this.initDetail()
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    initDetail () {
      const that = this
      getSpace({ spaceId: that.spaceId }).then((res) => {
        that.spaceInfo = res
      })

      this.initDevices()
    },
    showDeviceList () {
      this.deviceListModal = true
    },
    initDevices () {
      const that = this
      spaceDeviceList({ spaceId: that.spaceId }).then((res) => {
        that.deviceArray = res
      })
    },
    showProductPublicityModal () {
      this.$refs.productPublic.showModal()
    },

    initSinInBusiness () {
      const that = this
      listSinInBusiness({ investId: that.investmentId }).then((res) => {
        that.tableData = res
      })
    },
    handleChoiceDevices () {
      this.$refs.deviceChoice.showModal()
    }
  },
  watch: {
    spaceId () {
      this.initDetail()
    },
    beginUpdate () {
      this.initDetail()
    }
  },
  computed: {
    spaceId () {
      return this.$store.state.rentalSpace.spaceId
    },
    beginUpdate () {
      return this.$store.state.rentalSpace.beginUpdate
    }
  }
}
</script>
